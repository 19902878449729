import { format, formatDistance, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import Container from "./container";
import RoleList from "./role-list";

import * as styles from "./project.module.css";

const catIcon = (cat) => {
  if (cat && cat.icon) {
    return (
      <img
        className={styles.catIcon}
        key={cat.id}
        src={imageUrlFor(buildImageObj(cat.icon))
          .ignoreImageParams()
          .width(40)
          .height(40)
          .fit("fill")
          .auto("format")
          .url()}
        alt={cat.icon.alt}
      />
    );
  }
};

function Project(props) {
  const { _rawBody, title, categories, mainImage, members, publishedAt, relatedProjects } = props;
  const newImageUrl = imageUrlFor(buildImageObj(mainImage))
    .ignoreImageParams()
    .width(1200)
    // .height(Math.floor((9 / 16) * 1200))
    // .maxHeight(800)
    .fit("clip")
    .auto("format")
    .url();

  return (
    <article className={styles.root}>
      {props.mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          {/* style={{ backgroundImage: `url(${newImageUrl})` }} */}
          <img src={newImageUrl} alt={mainImage.alt} />
        </div>
      )}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                <br />
              </div>
            )}
            {members && members.length > 0 && <RoleList items={members} title="Project members" />}
            {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h2 className={styles.categoriesHeadline}>
                  <Link to={`/categories`}>Kategorien</Link>
                </h2>
                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>
                      <a href={"/category/" + category.id}>
                        {catIcon(category)} {category.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Relevante Projekte</h3>
                <ul>
                  {relatedProjects.map((project) => (
                    <li key={`related_${project._id}`}>
                      {project.slug ? (
                        <Link
                          className={styles.relatedProjectsLink}
                          to={`/project/${project.slug.current}`}
                        >
                          <img
                            src={imageUrlFor(buildImageObj(project.mainImage))
                              .ignoreImageParams()
                              .width(200)
                              .height(Math.floor((9 / 16) * 200))
                              .fit("crop")
                              .auto("format")
                              .url()}
                            alt={project.mainImage.alt}
                          />
                          {project.title}
                        </Link>
                      ) : (
                        <div>
                          <span>{project.title}</span>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  );
}

export default Project;
